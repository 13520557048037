import React from 'react';
import StockTable from '../Components/tables/StockTable';

const Overview: React.FC = () => {
    return (
        <div style={{ padding: 24 }}>
            <h1>Stock Information</h1>
            <StockTable />
        </div>
    )
}
export default Overview;