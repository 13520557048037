import React from 'react';
import { Table, Button, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { stockData } from '../../DummyData/stockdata'; 

interface StockData {
  key: string;
  name: string;
  ticker: string;
  price: number;
  pe: number;
  peg: number;
  eps: number;
  revenue1yr: number;
  revenue3yrs: number;
  revenue5yrs: number;
}

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Ticker',
    dataIndex: 'ticker',
    key: 'ticker',
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
    sorter: (a: StockData, b: StockData) => a.price - b.price,
    render: (text: number) => `$${text.toFixed(2)}`,
  },
  {
    title: 'P/E',
    dataIndex: 'pe',
    key: 'pe',
    sorter: (a: StockData, b: StockData) => a.pe - b.pe,
    render: (text: number) => text.toFixed(2),
  },
  {
    title: 'PEG',
    dataIndex: 'peg',
    key: 'peg',
    sorter: (a: StockData, b: StockData) => a.peg - b.peg,
    render: (text: number) => text.toFixed(2),
  },
  {
    title: 'EPS',
    dataIndex: 'eps',
    key: 'eps',
    sorter: (a: StockData, b: StockData) => a.eps - b.eps,
    render: (text: number) => text.toFixed(2),
  },
  {
    title: 'Revenue % 1 yr',
    dataIndex: 'revenue1yr',
    key: 'revenue1yr',
    sorter: (a: StockData, b: StockData) => a.revenue1yr - b.revenue1yr,
    render: (text: number) => `${text.toFixed(2)}%`,
  },
  {
    title: 'Revenue % 3 yrs',
    dataIndex: 'revenue3yrs',
    key: 'revenue3yrs',
    sorter: (a: StockData, b: StockData) => a.revenue1yr - b.revenue1yr,
    render: (text: number) => `${text.toFixed(2)}%`,
  },
  {
    title: 'Revenue % 5 yrs',
    dataIndex: 'revenue5yrs',
    key: 'revenue5yrs',
    sorter: (a: StockData, b: StockData) => a.revenue5yrs - b.revenue5yrs,
    render: (text: number) => `${text.toFixed(2)}%`,
  },
];

const StockTable: React.FC = () => {
  return (

    <Table
      dataSource={stockData}
      columns={columns}
      pagination={{ pageSize: 10 }}
      bordered
      scroll={{x: 1100}}
    />
  );
};

export default StockTable;
