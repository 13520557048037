export const stockData = [
  {
    key: '1',
    name: 'Apple Inc.',
    ticker: 'AAPL',
    price: 175.29,
    pe: 28.43,
    peg: 2.46,
    eps: 6.13,
    revenue1yr: 7.89,
    revenue3yrs: 8.23,
    revenue5yrs: 9.12,
  },
  {
    key: '2',
    name: 'Microsoft Corp.',
    ticker: 'MSFT',
    price: 345.67,
    pe: 31.76,
    peg: 2.14,
    eps: 10.92,
    revenue1yr: 15.67,
    revenue3yrs: 16.45,
    revenue5yrs: 18.23,
  },
  {
    key: '3',
    name: 'Alphabet Inc.',
    ticker: 'GOOGL',
    price: 2700.51,
    pe: 27.85,
    peg: 1.97,
    eps: 97.15,
    revenue1yr: 23.45,
    revenue3yrs: 21.78,
    revenue5yrs: 25.67,
  },
  {
    key: '4',
    name: 'Amazon.com Inc.',
    ticker: 'AMZN',
    price: 3450.14,
    pe: 59.74,
    peg: 2.70,
    eps: 60.12,
    revenue1yr: 14.32,
    revenue3yrs: 15.00,
    revenue5yrs: 17.21,
  },
  {
    key: '5',
    name: 'Tesla Inc.',
    ticker: 'TSLA',
    price: 720.35,
    pe: 90.82,
    peg: 1.55,
    eps: 7.92,
    revenue1yr: 12.65,
    revenue3yrs: 13.20,
    revenue5yrs: 14.75,
  },
  {
    key: '6',
    name: 'NVIDIA Corp.',
    ticker: 'NVDA',
    price: 600.23,
    pe: 45.92,
    peg: 1.95,
    eps: 12.43,
    revenue1yr: 17.55,
    revenue3yrs: 18.85,
    revenue5yrs: 19.95,
  },
  {
    key: '7',
    name: 'Meta Platforms Inc.',
    ticker: 'META',
    price: 320.48,
    pe: 23.77,
    peg: 2.34,
    eps: 13.50,
    revenue1yr: 9.10,
    revenue3yrs: 10.22,
    revenue5yrs: 11.33,
  },
  {
    key: '8',
    name: 'Johnson & Johnson',
    ticker: 'JNJ',
    price: 165.80,
    pe: 24.57,
    peg: 1.75,
    eps: 6.90,
    revenue1yr: 8.45,
    revenue3yrs: 8.78,
    revenue5yrs: 9.10,
  },
  {
    key: '9',
    name: 'Visa Inc.',
    ticker: 'V',
    price: 225.70,
    pe: 34.92,
    peg: 2.10,
    eps: 5.43,
    revenue1yr: 11.50,
    revenue3yrs: 12.00,
    revenue5yrs: 13.20,
  },
  {
    key: '10',
    name: 'Procter & Gamble Co.',
    ticker: 'PG',
    price: 148.30,
    pe: 26.70,
    peg: 2.00,
    eps: 5.56,
    revenue1yr: 7.80,
    revenue3yrs: 8.00,
    revenue5yrs: 8.20,
  },
  {
    key: '11',
    name: 'Coca-Cola Co.',
    ticker: 'KO',
    price: 63.40,
    pe: 22.40,
    peg: 2.60,
    eps: 2.83,
    revenue1yr: 5.90,
    revenue3yrs: 6.05,
    revenue5yrs: 6.20,
  },
  {
    key: '12',
    name: 'PepsiCo Inc.',
    ticker: 'PEP',
    price: 160.25,
    pe: 23.80,
    peg: 2.40,
    eps: 6.20,
    revenue1yr: 6.75,
    revenue3yrs: 7.00,
    revenue5yrs: 7.30,
  },
  {
    key: '13',
    name: 'Intel Corp.',
    ticker: 'INTC',
    price: 55.12,
    pe: 12.56,
    peg: 1.80,
    eps: 4.39,
    revenue1yr: 7.00,
    revenue3yrs: 7.25,
    revenue5yrs: 7.50,
  },
  {
    key: '14',
    name: 'IBM Corp.',
    ticker: 'IBM',
    price: 120.45,
    pe: 14.80,
    peg: 2.10,
    eps: 8.12,
    revenue1yr: 6.40,
    revenue3yrs: 6.60,
    revenue5yrs: 6.80,
  },
  {
    key: '15',
    name: 'Adobe Inc.',
    ticker: 'ADBE',
    price: 645.70,
    pe: 45.90,
    peg: 2.30,
    eps: 14.10,
    revenue1yr: 17.25,
    revenue3yrs: 18.10,
    revenue5yrs: 19.00,
  },
  {
    key: '16',
    name: 'Salesforce.com Inc.',
    ticker: 'CRM',
    price: 280.55,
    pe: 40.12,
    peg: 2.60,
    eps: 7.50,
    revenue1yr: 11.20,
    revenue3yrs: 12.00,
    revenue5yrs: 12.80,
  },
  {
    key: '17',
    name: 'Oracle Corp.',
    ticker: 'ORCL',
    price: 89.90,
    pe: 20.34,
    peg: 2.50,
    eps: 4.45,
    revenue1yr: 5.60,
    revenue3yrs: 5.85,
    revenue5yrs: 6.00,
  },
  {
    key: '18',
    name: 'Cisco Systems Inc.',
    ticker: 'CSCO',
    price: 52.70,
    pe: 18.10,
    peg: 2.20,
    eps: 2.91,
    revenue1yr: 4.90,
    revenue3yrs: 5.00,
    revenue5yrs: 5.20,
  },
  {
    key: '19',
    name: 'Qualcomm Inc.',
    ticker: 'QCOM',
    price: 135.20,
    pe: 19.90,
    peg: 2.30,
    eps: 6.79,
    revenue1yr: 10.30,
    revenue3yrs: 11.00,
    revenue5yrs: 11.50,
  },
  {
    key: '20',
    name: 'PayPal Holdings Inc.',
    ticker: 'PYPL',
    price: 78.45,
    pe: 40.50,
    peg: 2.70,
    eps: 1.92,
    revenue1yr: 9.50,
    revenue3yrs: 10.00,
    revenue5yrs: 10.25,
  },
];

