import React, { useEffect, useState } from 'react';

interface WeatherForecast {
  date: string;
  temperatureC: number;
  temperatureF: number;
  summary: string;
}

const SecurePage: React.FC = () => {
  const [weatherData, setWeatherData] = useState<WeatherForecast[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchWeatherData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/weatherforecast`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}` // Send token with request
          }
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data: WeatherForecast[] = await response.json();
        setWeatherData(data);
      } catch (error: any) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchWeatherData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <h1>Weather Forecast</h1>
      <ul>
        {weatherData.map((forecast, index) => (
          <li key={index}>
            <p>Date: {forecast.date}</p>
            <p>Temperature (C): {forecast.temperatureC}</p>
            <p>Temperature (F): {forecast.temperatureF}</p>
            <p>Summary: {forecast.summary}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SecurePage;