import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'antd';
import LoginForm from './forms/LoginForm';

const Login: React.FC = () => {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(true);
  console.log("ENV: ", process.env.REACT_APP_API_URL);

  const handleLogin = async (values: { username: string; password: string }) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(values)
      });

      if (!response.ok) {
        throw new Error('Login failed');
      }

      const { token } = await response.json();
      localStorage.setItem('authToken', token);
      setModalOpen(false);
      navigate('/'); // Redirect to homepage
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div>
      <Modal
      title={"Login to view content"}
        centered
        open={modalOpen}
        closable={false}
        footer={null}
      >
          <LoginForm onSubmit={handleLogin} />
      </Modal>
    </div>
  );
};

export default Login;
